import React from 'react';
import { FlexGrid, Row, Column, Button } from '@carbon/react';
import { Close } from '@carbon/react/icons';
import './SidePanel.scss';

const SidePanel = (props) => {
    const {
        title,
        closeFunction,
        secondaryButtonShown,
        primaryButtonShown,
        secondaryButtonDisabled,
        secondaryButtonFunction,
        secondaryButtonTag,
        primaryButtonDisabled,
        primaryButtonFunction,
        primaryButtonTag,
        children,
    } = { ...props };

    return (
        <div className='sidePanel'>
            <FlexGrid>
                <Row className='panelTitle'>
                    <Column lg={14} md={4} sm={2}>
                        <h4>{title}</h4>
                    </Column>
                    <Column lg={2} md={4} sm={2}>
                        <Close
                            size={24}
                            className='panelCancelIcon'
                            onClick={closeFunction}
                        />
                    </Column>
                </Row>
                <div className='sidePanelContent'>{children}</div>
                <Row className='sidePanelButtons'>
                    {secondaryButtonShown ? (
                        <Column className='alignSecondaryButton'>
                            <Button
                                kind='secondary'
                                disabled={secondaryButtonDisabled}
                                onClick={secondaryButtonFunction}
                                size='md'
                            >
                                {secondaryButtonTag}
                            </Button>
                        </Column>
                    ) : null}
                    {primaryButtonShown ? (
                        <Column className='alignPrimaryButton'>
                            <Button
                                kind='primary'
                                disabled={primaryButtonDisabled}
                                onClick={() => primaryButtonFunction()}
                                size='md'
                            >
                                {primaryButtonTag}
                            </Button>
                        </Column>
                    ) : null}
                </Row>
            </FlexGrid>
        </div>
    );
};

export default SidePanel;
