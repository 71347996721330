/* eslint-disable react/jsx-filename-extension */
import './App.scss';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter/AppRouter.jsx';
import AppHeader from './components/Header/Header.jsx';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary.jsx';
import AppNotification from './components/AppNotification/AppNotification.jsx';

const App = () => (
    <BrowserRouter>
        <ErrorBoundary>
            <AppHeader />
            <AppRouter />
            <AppNotification />
        </ErrorBoundary>
    </BrowserRouter>
);

export default App;
