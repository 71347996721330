import * as actionTypes from '../actionTypes/actionTypes.js';

// Elements List
export const fetchAllElements = (results) => ({
    type: actionTypes.FETCH_ELEMENTS_LIST,
    payload: results,
});

export const clearElementsList = () => ({
    type: actionTypes.CLEAR_ELEMENTS_LIST,
});

export const setElementsPagination = (results) => ({
    type: actionTypes.ELEMENTS_LIST_PAGINATION,
    payload: results,
});

export const clearEditElement = (results) => ({
    type: actionTypes.CLEAR_EDIT_ELEMENT,
    payload: results,
});

export const editElementNewData = (results) => ({
    type: actionTypes.EDIT_ELEMENT_NEW_DATA,
    payload: results,
});

export const setElementToEdit = (results) => ({
    type: actionTypes.SET_ELEMENT_TO_EDIT,
    payload: results,
});

export const fetchElementListMasterData = (results) => ({
    type: actionTypes.FETCH_ELEMENTS_LIST_MASTER_DATA,
    payload: results,
});

export const fetchKeywordListMasterData = (results) => ({
    type: actionTypes.FETCH_KEYWORD_LIST_MASTER_DATA,
    payload: results,
});

// Notification
export const setNotification = (results) => ({
    type: actionTypes.SET_NOTIFICATION,
    payload: results,
});

// Element Search
export const setSearchBarInput = (input) => ({
    type: actionTypes.SEARCH_BAR_INPUT,
    payload: input,
});
