import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ElementList from '../pages/ElementList/ElementList.jsx';

const AppRouter = () => (
    <Routes>
        <Route path='/' element={<Navigate replace to='/elementList' />} />
        <Route exact path='/elementList' element={<ElementList />} />
        <Route path='*' element={<ElementList />} />
    </Routes>
);

export default AppRouter;
