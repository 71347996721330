import React, { useEffect, useState } from 'react';
import {
    HeaderContainer,
    Header,
    HeaderMenuButton,
    SkipToContent,
    HeaderName,
    HeaderNavigation,
    HeaderMenuItem,
    HeaderGlobalBar,
    HeaderGlobalAction,
    SideNavLink,
    SideNavItems,
    SideNav,
    Theme,
} from '@carbon/react';

import { Notification } from '@carbon/react/icons';

const AppHeader = () => (
    <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
            <Theme theme='g100'>
                <Header aria-label='IBM Element Repository'>
                    <SkipToContent />
                    <HeaderMenuButton
                        aria-label='Open menu'
                        onClick={onClickSideNavExpand}
                        isActive={isSideNavExpanded}
                    />
                    <HeaderName prefix='IBM'>Element Repository</HeaderName>
                    <HeaderNavigation aria-label='IBM Element Repository'>
                        <HeaderMenuItem>Elements</HeaderMenuItem>
                    </HeaderNavigation>
                    <HeaderGlobalBar>
                        <HeaderGlobalAction
                            aria-label='Notifications'
                            onClick=''
                        >
                            <Notification size={20} />
                        </HeaderGlobalAction>
                    </HeaderGlobalBar>
                    <SideNav
                        aria-label='Side navigation'
                        expanded={isSideNavExpanded}
                        isPersistent={false}
                    >
                        <SideNavItems>
                            <SideNavLink isActive>Elements</SideNavLink>
                            <SideNavLink>Golden Rules</SideNavLink>
                        </SideNavItems>
                    </SideNav>
                </Header>
            </Theme>
        )}
    />
);

export default AppHeader;
