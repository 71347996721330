import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { InlineNotification } from '@carbon/react';
import { setNotification } from '../../redux/actions/actions';

const AppNotification = () => {
    const dispatch = useDispatch();
    const { notificationData } = useSelector(
        (state) => ({
            notificationData: state.notification,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (notificationData.isOpen === true) {
            closeNotification();
        }
    }, [notificationData]);

    const closeNotification = () => {
        setTimeout(() => {
            const notificationObj = {
                isOpen: false,
                subtitle: null,
                title: null,
                kind: 'success',
            };
            dispatch(setNotification(notificationObj));
        }, 9000);
    };

    return notificationData.isOpen ? (
        <InlineNotification
            onCloseButtonClick={closeNotification}
            subtitle={notificationData.subtitle}
            title={notificationData.title}
            kind={notificationData.kind}
            className='notification'
            lowContrast
        />
    ) : null;
};

export default AppNotification;
