import React, { useEffect, useState } from 'react';
import {
    FlexGrid,
    Row,
    Column,
    DataTable,
    DataTableSkeleton,
    Button,
    Pagination,
    MultiSelect,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableSelectAll,
    TableSelectRow,
    TableToolbar,
    TableToolbarSearch,
    TableToolbarContent,
    TableBatchAction,
    TableBatchActions,
    Popover,
    PopoverContent,
} from '@carbon/react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Add, TrashCan, Edit, Search, Filter } from '@carbon/react/icons';
import {
    setNotification,
    setElementsPagination,
    setElementToEdit,
    editElementNewData,
    clearEditElement,
    clearElementsList,
    setSearchBarInput,
} from '../../redux/actions/actions.js';
import DeleteElementModal from '../../components/Modals/DeleteElementModal.jsx';
import {
    getElements,
    deleteElements,
    createElement,
    editElement,
} from '../../apis/elements.js';
import { getMasterData, getKeywordData } from '../../apis/masterData';
import SidePanel from '../../components/SidePanel/SidePanel.jsx';
import AddEditElement from '../../components/SidePanel/AddEditElement/AddEditElement.jsx';
import {
    createSearchRequest,
    getDataAttributes,
} from '../../helper/searchHelper.js';

const ElementList = () => {
    const dispatch = useDispatch();
    const {
        elements,
        paginationData,
        editElementData,
        searchBarInput,
        elementsMasterData,
        keywordMasterData,
    } = useSelector(
        (state) => ({
            elements: state.elements.elements_list,
            paginationData: state.elements.paginationData,
            editElementData: state.elements.editElementData,
            searchBarInput: state.elements.searchBarInput,
            elementsMasterData: state.elements.elementsList_masterData,
            keywordMasterData: state.elements.keyword_masterData,
        }),
        shallowEqual
    );
    const [sidePanelState, setSidePanelState] = useState({ open: false });
    const [elementInfo, setElementInfo] = useState({
        element_name: '',
        category: '',
        definition: '',
        relevance: '',
        risk_factors: '',
        owner: '',
        doc_section: '',
        key_words: '',
    });
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [selectedRowsState, setSelectedRowsState] = useState(null);
    const [deleteModalBtnDisable, setDeleteModalBtnDisable] = useState(false);
    const [showSearchButton, setShowSearchButton] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);
    const [sortDirection, setSortDirection] = useState('NONE');
    const [sortColumn, setSortColumn] = useState('');
    const [categoryItems, setCategoryItems] = useState([]);
    const [relevanceItems, setRelevanceItems] = useState([]);
    const [riskFactorItems, setRiskFactorItems] = useState([]);
    const [docSectionsItems, setDocSectionsItems] = useState([]);
    const [keywordItems, setKeywordItems] = useState([]);
    const [elementFilterData, setElementFilterData] = useState([]);
    const [advancedFilterData, setAdvancedFilterData] = useState(null);
    const [selectedCell, setSelectedCell] = useState('');

    const HEADERS = [
        {
            key: 'element_id',
            header: 'Element ID',
            isSortable: false,
        },
        {
            key: 'element_name',
            header: 'Element Name',
            isSortable: true,
        },
        { key: 'category', header: 'Element Category', isSortable: true },
        { key: 'relevance', header: 'Relevance', isSortable: true },
        {
            key: 'risk_factors',
            header: 'Risk Factor',
            isSortable: true,
        },
        { key: 'definition', header: 'Definition', isSortable: false },
        {
            key: 'doc_section',
            header: 'Document Section',
            isSortable: false,
        },
        {
            key: 'key_words',
            header: 'Keywords',
            isSortable: false,
        },
        {
            key: 'edit',
            header: '',
            isSortable: false,
        },
    ];

    useEffect(() => {
        getMasterDataAPI();
        getKeywordDataAPI();
    }, []);

    useEffect(() => {
        if (elementsMasterData !== null) {
            const filterOptions = getDataAttributes(elementsMasterData);
            setCategoryItems(filterOptions.newCategoryItems);
            setRelevanceItems(filterOptions.newRelevanceitems);
            setRiskFactorItems(filterOptions.newRiskFactorItems);
            setDocSectionsItems(filterOptions.newDocSectionsItems);
        }
    }, [elementsMasterData]);

    useEffect(() => {
        if (elements !== null) {
            fetchElements();
        }
    }, [paginationData]);

    useEffect(() => {
        if (advancedFilterData !== null) {
            callAdvancedFilters(advancedFilterData, elementFilterData);
        }
    }, [advancedFilterData]);

    useEffect(() => {
        newElementValidation(elementInfo);
        if (sidePanelState?.content === 'editElement') {
            dispatch(
                editElementNewData({
                    element_id: elementInfo.element_id,
                    element_name: elementInfo.element_name,
                    category: elementInfo.category,
                    definition: elementInfo.definition,
                    relevance: elementInfo.relevance,
                    risk_factors: elementInfo.risk_factors,
                    doc_section: multiselectPipeJoin(elementInfo.doc_section),
                    key_words: multiselectPipeJoin(elementInfo.key_words),
                })
            );
        }
    }, [elementInfo]);

    useEffect(() => {
        if (sidePanelState?.content === 'editElement') {
            const hasChanged = [];
            const isEmpty = [];
            editElementData?.element_edit.map((item) => {
                if (
                    (item.field_name === 'doc_section' ||
                        item.field_name === 'key_words') &&
                    !isArrayEqual(item.old_value, item.new_value)
                ) {
                    hasChanged.push(item.field_name);
                } else if (
                    item.field_name !== 'doc_section' &&
                    item.field_name !== 'key_words' &&
                    item.new_value.match(/^ *$/) === null &&
                    item.old_value !== item.new_value
                ) {
                    hasChanged.push(item.field_name);
                }
                if (item.new_value.match(/^ *$/) !== null) {
                    isEmpty.push(item.field_name);
                }
            });
            hasChanged.length && isEmpty.length === 0
                ? setSidePanelState((prevState) => ({
                      ...prevState,
                      primaryButtonDisabled: false,
                  }))
                : setSidePanelState((prevState) => ({
                      ...prevState,
                      primaryButtonDisabled: true,
                  }));
        }
    }, [editElementData]);

    useEffect(() => {
        onblurSearchBar();
        setSearchQuery(searchBarInput);
    }, [elements]);

    useEffect(() => {
        fetchElements();
    }, [sortDirection, sortColumn]);

    const getElementsAPI = async (paginationDataInput) => {
        const request = {
            offset:
                paginationDataInput.currentPageSize *
                (paginationDataInput.currentPage - 1),
            count: paginationDataInput.currentPageSize,
        };
        if (sortDirection !== 'NONE') {
            request.sort_criteria = {
                sort_field: sortColumn,
                sort_type:
                    sortDirection === 'ASC'
                        ? 1
                        : sortDirection === 'DESC'
                        ? 0
                        : null,
            };
        }
        await getElements(request, dispatch);
    };

    const getMasterDataAPI = async () => {
        const request = {};
        await getMasterData(request, dispatch);
    };

    const getKeywordDataAPI = async () => {
        const request = {};
        const res = await getKeywordData(request, dispatch);
        keywordDropdownFormat(res.elementkeywords_master);
    };

    const keywordDropdownFormat = (keywordData) => {
        const updatedKeywordData = [];
        keywordData.forEach((keyword) => {
            updatedKeywordData.push({
                id: keyword.keyword_id,
                label: keyword.name,
                type: 'keyword',
                created_by: keyword.created_by,
                created_on: keyword.created_on,
                keyword_id: keyword.keyword_id,
                name: keyword.name,
                updated_on: keyword.updated_on,
            });
        });
        setKeywordItems(updatedKeywordData);
    };

    const dataTableRows = (rowsData) => {
        const rowsArray = [];
        if (rowsData !== null) {
            rowsData.map((row) => {
                rowsArray.push({
                    id: row.element_id,
                    element_id: row.element_id,
                    element_name: row.element_name,
                    category: row.category,
                    definition: row.definition,
                    relevance: row.relevance,
                    risk_factors: row.risk_factors,
                    doc_section: row.doc_section,
                    key_words: row.key_words,
                    edit: (
                        <Edit
                            size={16}
                            className='editIcon'
                            onClick={() => openEditPanel(row)}
                        />
                    ),
                });
            });
        }
        return rowsArray;
    };

    const clickDeleteElements = (selectedRows) => {
        setSelectedRowsState(selectedRows);
        setDeleteModal(true);
    };

    const openPanel = () => {
        setSelectedCell('');
        setSidePanelState({
            open: true,
            title: 'Add Element',
            primaryButtonTag: 'Create',
            primaryButtonDisabled: true,
            secondaryButtonTag: 'Cancel',
            secondaryButtonDisabled: false,
            primaryButtonShown: true,
            secondaryButtonShown: true,
            secondaryButtonFunction: () => closeAddPanel(),
            closeFunction: () => closeAddPanel(),
            content: 'addElement',
        });
    };

    const openEditPanel = (rowData) => {
        setSelectedCell('');
        setElementInfo({
            element_name: rowData.element_name,
            category: rowData.category,
            definition: rowData.definition,
            relevance: rowData.relevance,
            risk_factors: rowData.risk_factors,
            doc_section: rowData.doc_section,
            key_words: rowData.key_words,
            owner: '',
            element_id: rowData.element_id,
        });
        dispatch(
            setElementToEdit({
                element_id: rowData.element_id,
                element_name: rowData.element_name,
                category: rowData.category,
                definition: rowData.definition,
                relevance: rowData.relevance,
                risk_factors: rowData.risk_factors,
                doc_section: multiselectPipeJoin(rowData.doc_section),
                key_words: multiselectPipeJoin(rowData.key_words),
            })
        );
        setSidePanelState({
            open: true,
            title: 'Edit Element',
            primaryButtonTag: 'Save',
            primaryButtonDisabled: true,
            secondaryButtonTag: 'Cancel',
            secondaryButtonDisabled: false,
            primaryButtonShown: true,
            secondaryButtonShown: true,
            secondaryButtonFunction: () => closeAddPanel(),
            closeFunction: () => closeAddPanel(),
            content: 'editElement',
        });
    };

    const clickElementSidePanel = async (
        requestData,
        apiCall,
        apiType,
        notificationMessage
    ) => {
        let request = null;

        if (apiType === 'create') {
            request = {
                owner: requestData.owner,
                element_name: requestData.element_name,
                category: requestData.category,
                relevance: requestData.relevance,
                risk_factors: requestData.risk_factors,
                definition: requestData.definition,
                doc_section: multiselectPipeJoin(requestData.doc_section),
                key_words: multiselectPipeJoin(requestData.key_words),
            };
        } else if (apiType === 'edit') {
            request = requestData;
        }
        setSidePanelState((prevState) => ({
            ...prevState,
            primaryButtonDisabled: true,
        }));

        const response = await apiCall(request);
        if (response['status-code'] === 0) {
            const notificationObj = {
                isOpen: true,
                subtitle: '',
                title: `${notificationMessage}`,
                kind: 'success',
            };
            dispatch(setNotification(notificationObj));
            fetchElements();
            getKeywordDataAPI();
        }
        closeAddPanel();
    };

    const closeAddPanel = () => {
        dispatch(clearEditElement());
        setSidePanelState({ open: false });
        setElementInfo({
            element_name: '',
            category: '',
            definition: '',
            relevance: '',
            risk_factors: '',
            owner: '',
            doc_section: '',
            key_words: '',
        });
        keywordDropdownFormat(keywordMasterData.elementkeywords_master);
    };

    const newElementValidation = (elementData) => {
        const data = elementData;
        if (data !== undefined) {
            const isEmpty = Object.keys(data).filter((key) => {
                if (key === 'doc_section' || key === 'key_words') {
                    return data[key].length === 0;
                }
                return data[key].match(/^ *$/) !== null && key !== 'owner';
            });
            if (isEmpty.length === 0) {
                setSidePanelState((prevState) => ({
                    ...prevState,
                    primaryButtonDisabled: false,
                }));
            } else {
                setSidePanelState((prevState) => ({
                    ...prevState,
                    primaryButtonDisabled: true,
                }));
            }
        }
    };

    const deleteElementsAPI = async (selectedRows) => {
        const request = {
            element_ids: [],
        };
        selectedRows !== null &&
            selectedRows.map((row) => {
                request.element_ids.push(row.id);
            });
        const response = await deleteElements(request, dispatch);
        if (response['status-code'] === 0) {
            const notificationObj = {
                isOpen: true,
                subtitle: '',
                title: 'Element(s) deleted successfully',
                kind: 'success',
            };
            dispatch(setNotification(notificationObj));
            fetchElements();
            setDeleteModal(false);
            setDeleteModalBtnDisable(false);
        }
    };

    const changePagination = (pageinfo) => {
        const data = {
            currentPageSize: pageinfo.pageSize,
            currentPage: pageinfo.page,
        };
        dispatch(setElementsPagination(data));
    };

    const searchInputChange = (e) => {
        if (e.target !== undefined) {
            e.target.value.length > 0 && setSearchQuery(e.target.value);
            e.target.value.length === 0 && setSearchQuery(null);
        }
    };

    const callSearch = (searchFilterData) => {
        if (searchQuery !== null && searchQuery.length > 0) {
            const resetPaginationData = resetPagination(
                searchQuery,
                'searchQuery'
            );
            dispatch(setSearchBarInput(searchQuery));
            dispatch(clearElementsList());
            const data = {
                type: 101, // type for search bar
                data_name: 'table_keywords', // name for search bar
                data_type: 0, // 0 is string
                data_operator: 4, // 4 is equal to
                query: searchQuery,
                is_exact: 0,
            };

            const updatedFilterData = searchFilterData
                .filter((advFilter) => advFilter.type !== 101)
                .concat(data);
            setElementFilterData(updatedFilterData);
            const searchRequest = createSearchRequest(
                updatedFilterData,
                resetPaginationData !== null
                    ? resetPaginationData
                    : paginationData,
                sortDirection,
                sortColumn
            );
            getElements(searchRequest, dispatch);
        } else {
            clearSearch(searchFilterData);
        }
    };

    const callAdvancedFilters = (advFilters, searchFilterData) => {
        const filterQueries =
            advFilters !== null ? Object.values(advFilters).flat() : null;
        if (
            filterQueries !== undefined &&
            filterQueries !== null &&
            filterQueries.length > 0
        ) {
            dispatch(clearElementsList());
            const data = [];
            filterQueries.map((query) => {
                data.push({
                    type: 100, // type for advanced filter
                    data_name: query.type, // name for advanced filter
                    data_type: 0, // 0 is string
                    data_operator: 4, // 4 is equal to
                    query: query.label,
                    is_exact: 0,
                });
            });

            const resetCheck =
                searchFilterData.length &&
                data.toString() !==
                    searchFilterData
                        .filter((item) => item.type === 100)
                        .toString();

            const resetPaginationData = resetPagination(
                !!resetCheck,
                'filterQuery'
            );

            const updatedFilterData = searchFilterData
                .filter((advFilter) => advFilter.type !== 100)
                .concat(data);
            setElementFilterData(updatedFilterData);
            const searchRequest = createSearchRequest(
                updatedFilterData,
                resetPaginationData !== null
                    ? resetPaginationData
                    : paginationData,
                sortDirection,
                sortColumn
            );
            getElements(searchRequest, dispatch);
        } else {
            const resetPaginationData = {
                currentPageSize: paginationData.currentPageSize,
                currentPage: 1,
            };
            const updatedFilterData = searchFilterData.filter(
                (advFilter) => advFilter.type !== 100
            );
            setElementFilterData(updatedFilterData);
            dispatch(clearElementsList());
            if (updatedFilterData.length) {
                const searchRequest = createSearchRequest(
                    updatedFilterData,
                    resetPaginationData !== null
                        ? resetPaginationData
                        : paginationData,
                    sortDirection,
                    sortColumn
                );
                getElements(searchRequest, dispatch);
            } else {
                getElementsAPI(resetPaginationData);
            }
        }
    };

    const onAdvancedFilterChange = (type, data) => {
        setAdvancedFilterData((prevState) => ({
            ...prevState,
            [type]: data,
        }));

        if (data.length) {
            if (type === 'category') {
                setCategoryItems((prevState) =>
                    prevState.map((item) => {
                        if (item.label !== data[0].label) {
                            return {
                                ...item,
                                disabled: true,
                            };
                        }
                        return item;
                    })
                );
            } else if (type === 'relevance') {
                setRelevanceItems((prevState) =>
                    prevState.map((item) => {
                        if (item.label !== data[0].label) {
                            return {
                                ...item,
                                disabled: true,
                            };
                        }
                        return item;
                    })
                );
            } else if (type === 'riskFactors') {
                setRiskFactorItems((prevState) =>
                    prevState.map((item) => {
                        if (item.label !== data[0].label) {
                            return {
                                ...item,
                                disabled: true,
                            };
                        }
                        return item;
                    })
                );
            }
        } else if (data.length === 0) {
            if (type === 'category') {
                setCategoryItems((prevState) =>
                    prevState.map((item) => {
                        delete item.disabled;
                        return item;
                    })
                );
            } else if (type === 'relevance') {
                setRelevanceItems((prevState) =>
                    prevState.map((item) => {
                        delete item.disabled;
                        return item;
                    })
                );
            } else if (type === 'riskFactors') {
                setRiskFactorItems((prevState) =>
                    prevState.map((item) => {
                        delete item.disabled;
                        return item;
                    })
                );
            }
        }
    };

    const onblurSearchBar = (searchFilterData) => {
        if (
            (searchQuery === '' || searchQuery === null) &&
            searchBarInput === null
        ) {
            setShowSearchButton(false);
            setIsSearchBarExpanded(false);
        } else if (
            (searchQuery === '' || searchQuery === null) &&
            searchBarInput !== null
        ) {
            callSearch(searchFilterData);
        }
    };

    const clearSearch = (searchFilterData) => {
        const query = null;
        setSearchQuery(query);
        const updatedFilterData = searchFilterData.filter(
            (advFilter) => advFilter.type !== 101
        );
        setElementFilterData(updatedFilterData);
        const resetPaginationData = resetPagination(query, 'searchQuery');
        dispatch(setSearchBarInput(null));
        dispatch(clearElementsList());
        if (updatedFilterData.length) {
            const searchRequest = createSearchRequest(
                updatedFilterData,
                resetPaginationData !== null
                    ? resetPaginationData
                    : paginationData,
                sortDirection,
                sortColumn
            );
            getElements(searchRequest, dispatch);
        } else {
            getElementsAPI(resetPaginationData);
        }
    };

    const searchOnEnter = (event, searchFilterData) => {
        if (event.keyCode === 13) {
            callSearch(searchFilterData);
        }
    };

    const resetPagination = (query, type) => {
        let resetPaginationData = null;
        if (type === 'searchQuery' && query !== searchBarInput) {
            resetPaginationData = {
                currentPageSize: paginationData.currentPageSize,
                currentPage: 1,
            };
            dispatch(setElementsPagination(resetPaginationData));
        } else if (type === 'filterQuery' && query) {
            resetPaginationData = {
                currentPageSize: paginationData.currentPageSize,
                currentPage: 1,
            };
            dispatch(setElementsPagination(resetPaginationData));
        }
        return resetPaginationData;
    };

    const customSort = (header) => {
        if (header.key === sortColumn) {
            if (sortDirection === 'ASC') {
                setSortDirection('DESC');
            } else if (sortDirection === 'DESC') {
                setSortDirection('NONE');
                setSortColumn('');
            }
        } else {
            setSortColumn(header.key);
            setSortDirection('ASC');
        }
    };

    const fetchElements = () => {
        if (elementFilterData.length) {
            callAdvancedFilters(advancedFilterData, elementFilterData);
        } else {
            dispatch(clearElementsList());
            getElementsAPI(paginationData);
        }
    };

    const openElementDataPopup = (cellID, rowNumber, cellIndex) => {
        const currentCell = `${cellID}(${rowNumber})(${cellIndex})`;
        if (currentCell === selectedCell) {
            return true;
        }
        return false;
    };

    const changeSelectedCell = (cellID, rowNumber, cellIndex, valueLength) => {
        const currentCell = `${cellID}(${rowNumber})(${cellIndex})`;
        if (currentCell === selectedCell || valueLength === 0) {
            setSelectedCell('');
        } else {
            setSelectedCell(currentCell);
        }
    };

    const multiselectPipeJoin = (elementValue) => {
        const arrayToString = elementValue.some(
            (item) => typeof item === 'object'
        )
            ? elementValue.map((element) => element.label).join('|')
            : elementValue.join('|');

        return arrayToString;
    };

    const isArrayEqual = (oldValue, NewValue) => {
        const oldValueArray = oldValue.split('|');
        const newValueArray = NewValue.split('|');
        const isEqual = newValueArray.map((value) =>
            oldValueArray.includes(value)
        );
        if (
            isEqual.includes(false) ||
            oldValueArray.length !== newValueArray.length
        ) {
            return false;
        }
        return true;
    };

    return (
        <FlexGrid className='pt3rem' fullWidth>
            <DeleteElementModal
                isOpen={isDeleteModal}
                setDeleteModal={setDeleteModal}
                selectedRowsState={selectedRowsState}
                deleteElementsAPI={deleteElementsAPI}
                setDeleteModalBtnDisable={setDeleteModalBtnDisable}
                deleteModalBtnDisable={deleteModalBtnDisable}
            />
            <Row>
                <Column lg={16} md={8} sm={4} className='banner'>
                    <h4>Element List</h4>
                </Column>
            </Row>
            <Row className='filterRow'>
                {/* Save for future update */}
                {/* <Column lg={5} md={2} sm={1} className='noPaddingRight'>
                    <MultiSelect
                        ariaLabel='MultiSelect'
                        className='multiSelectFilter'
                        id='carbon-multiselect-example'
                        items={multiItems}
                        label='Element Name'
                        titleText={
                            <span className='filterTitleText'>
                                <Filter size={24} />
                                <strong>Filter by:</strong>
                            </span>
                        }
                        type='inline'
                    />
                </Column> */}
                <Column lg={5} md={2} sm={1} className='noPaddingRight'>
                    <MultiSelect
                        ariaLabel='MultiSelect'
                        className='multiSelectFilter'
                        id='carbon-multiselect-example'
                        items={categoryItems}
                        label='Element Category'
                        titleText={
                            <span className='filterTitleText'>
                                <Filter size={24} />
                                <strong>Filter by:</strong>
                            </span>
                        }
                        type='inline'
                        onChange={(element) => {
                            onAdvancedFilterChange(
                                'category',
                                element?.selectedItems
                            );
                        }}
                    />
                </Column>
                <Column lg={3} md={2} sm={1}>
                    <MultiSelect
                        ariaLabel='MultiSelect'
                        className='multiSelectFilter'
                        id='carbon-multiselect-example'
                        items={relevanceItems}
                        label='Relevance'
                        titleText=''
                        type='inline'
                        onChange={(element) => {
                            onAdvancedFilterChange(
                                'relevance',
                                element?.selectedItems
                            );
                        }}
                    />
                </Column>
                <Column lg={3} md={2} sm={1}>
                    <MultiSelect
                        ariaLabel='MultiSelect'
                        className='multiSelectFilter'
                        id='carbon-multiselect-example'
                        items={riskFactorItems}
                        label='Risk Factor'
                        titleText=''
                        type='inline'
                        onChange={(element) => {
                            onAdvancedFilterChange(
                                'riskFactors',
                                element?.selectedItems
                            );
                        }}
                    />
                </Column>
            </Row>
            <Row>
                <Column lg={16} md={8} sm={4}>
                    {elements === null ? (
                        <DataTableSkeleton
                            headers={HEADERS}
                            columnCount={HEADERS.length}
                            rowCount={10}
                        />
                    ) : (
                        <DataTable
                            rows={dataTableRows(elements.results)}
                            headers={HEADERS}
                            isSortable
                        >
                            {({
                                rows,
                                headers,
                                getHeaderProps,
                                getRowProps,
                                getSelectionProps,
                                getTableProps,
                                getTableContainerProps,
                                getBatchActionProps,
                                getToolbarProps,
                                selectedRows,
                            }) => (
                                <TableContainer {...getTableContainerProps()}>
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableBatchActions
                                            {...getBatchActionProps()}
                                        >
                                            <TableBatchAction
                                                renderIcon={TrashCan}
                                                iconDescription='Delete the selected elements'
                                                onClick={() =>
                                                    clickDeleteElements(
                                                        selectedRows
                                                    )
                                                }
                                            >
                                                Delete
                                            </TableBatchAction>
                                        </TableBatchActions>
                                        <TableToolbarContent>
                                            <TableToolbarSearch
                                                onChange={(e) =>
                                                    searchInputChange(e)
                                                }
                                                placeholder='Search by element name or definition'
                                                onExpand={() => {
                                                    setShowSearchButton(true);
                                                    setIsSearchBarExpanded(
                                                        true
                                                    );
                                                }}
                                                onBlur={() =>
                                                    onblurSearchBar(
                                                        elementFilterData
                                                    )
                                                }
                                                onClear={() =>
                                                    clearSearch(
                                                        elementFilterData
                                                    )
                                                }
                                                expanded={isSearchBarExpanded}
                                                defaultValue={
                                                    searchQuery || null
                                                }
                                                onKeyDown={(e) =>
                                                    searchOnEnter(
                                                        e,
                                                        elementFilterData
                                                    )
                                                }
                                            />
                                            {showSearchButton && (
                                                <Button
                                                    size='sm'
                                                    kind='primary'
                                                    onClick={() =>
                                                        searchQuery !== null
                                                            ? callSearch(
                                                                  elementFilterData
                                                              )
                                                            : null
                                                    }
                                                    iconDescription='Search button'
                                                    renderIcon={() => (
                                                        <Search size={16} />
                                                    )}
                                                    className='searchButton'
                                                />
                                            )}
                                            <Button
                                                size='sm'
                                                kind='primary'
                                                onClick={openPanel}
                                                renderIcon={() => (
                                                    <Add size={16} />
                                                )}
                                            >
                                                Add Element
                                            </Button>
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                <TableSelectAll
                                                    {...getSelectionProps()}
                                                />
                                                {headers.map((header, i) => (
                                                    <TableHeader
                                                        key={i}
                                                        {...getHeaderProps({
                                                            header,
                                                        })}
                                                        isSortable={
                                                            header.isSortable
                                                        }
                                                        onClick={() =>
                                                            customSort(header)
                                                        }
                                                        sortDirection={
                                                            sortDirection
                                                        }
                                                        isSortHeader={
                                                            header.key ===
                                                            sortColumn
                                                        }
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, i) => (
                                                <TableRow
                                                    key={i}
                                                    {...getRowProps({ row })}
                                                >
                                                    <TableSelectRow
                                                        {...getSelectionProps({
                                                            row,
                                                        })}
                                                    />
                                                    {row.cells.map(
                                                        (cell, index) => (
                                                            <TableCell
                                                                key={cell.id}
                                                            >
                                                                {cell.id.includes(
                                                                    'doc_section'
                                                                ) ||
                                                                cell.id.includes(
                                                                    'key_words'
                                                                ) ? (
                                                                    <Popover
                                                                        className='elementDataPopover'
                                                                        open={openElementDataPopup(
                                                                            cell.id,
                                                                            i,
                                                                            index
                                                                        )}
                                                                        autoAlign
                                                                        highContrast
                                                                    >
                                                                        <p
                                                                            className='tableCountValue'
                                                                            onClick={() => {
                                                                                changeSelectedCell(
                                                                                    cell.id,
                                                                                    i,
                                                                                    index,
                                                                                    cell
                                                                                        .value
                                                                                        .length
                                                                                );
                                                                            }}
                                                                        >
                                                                            {
                                                                                cell
                                                                                    .value
                                                                                    .length
                                                                            }
                                                                        </p>
                                                                        <PopoverContent className='popOverBox'>
                                                                            {cell.value?.join(
                                                                                ', '
                                                                            )}
                                                                        </PopoverContent>
                                                                    </Popover>
                                                                ) : (
                                                                    cell.value
                                                                )}
                                                            </TableCell>
                                                        )
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Pagination
                                        backwardText='Previous page'
                                        forwardText='Next page'
                                        itemsPerPageText='Elements per page:'
                                        onChange={(page) =>
                                            changePagination(page)
                                        }
                                        page={paginationData.currentPage}
                                        pageSize={
                                            paginationData.currentPageSize
                                        }
                                        pageSizes={[10, 20, 30, 40, 50, 100]}
                                        size='md'
                                        totalItems={elements.total_count}
                                    />
                                </TableContainer>
                            )}
                        </DataTable>
                    )}
                </Column>
                {sidePanelState.open && (
                    <SidePanel
                        title={sidePanelState.title}
                        primaryButtonTag={sidePanelState.primaryButtonTag}
                        primaryButtonDisabled={
                            sidePanelState.primaryButtonDisabled
                        }
                        secondaryButtonTag={sidePanelState.secondaryButtonTag}
                        secondaryButtonDisabled={
                            sidePanelState.secondaryButtonDisabled
                        }
                        primaryButtonShown={sidePanelState.primaryButtonShown}
                        secondaryButtonShown={
                            sidePanelState.secondaryButtonShown
                        }
                        primaryButtonFunction={
                            sidePanelState.content === 'addElement'
                                ? () =>
                                      clickElementSidePanel(
                                          elementInfo,
                                          createElement,
                                          'create',
                                          'Element added successfully'
                                      )
                                : sidePanelState.content === 'editElement'
                                ? () =>
                                      clickElementSidePanel(
                                          editElementData,
                                          editElement,
                                          'edit',
                                          'Element edited successfully'
                                      )
                                : null
                        }
                        secondaryButtonFunction={
                            sidePanelState.secondaryButtonFunction
                        }
                        closeFunction={sidePanelState.closeFunction}
                    >
                        {(sidePanelState.content === 'addElement' ||
                            sidePanelState.content === 'editElement') && (
                            <AddEditElement
                                setElementInfo={setElementInfo}
                                elementInfo={elementInfo}
                                categoryItems={categoryItems}
                                relevanceItems={relevanceItems}
                                riskFactorItems={riskFactorItems}
                                docSectionsItems={docSectionsItems}
                                keywordItems={keywordItems}
                                setKeywordItems={setKeywordItems}
                                contentType={sidePanelState.content}
                            />
                        )}
                    </SidePanel>
                )}
            </Row>
        </FlexGrid>
    );
};

export default ElementList;
