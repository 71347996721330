import { API_URL } from '../config/config';
import { fetchAllElements, setNotification } from '../redux/actions/actions.js';

export const getElements = async (request, dispatch) => {
    new Promise((resolve, reject) => {
        const apiUrl = `${API_URL}getElements`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('');
            })
            .then((responseJson) => {
                if (responseJson['status-code'] === 0) {
                    dispatch(fetchAllElements(responseJson));
                    resolve(responseJson);
                } else {
                    dispatch(fetchAllElements(responseJson));
                    reject(responseJson);
                    throw new Error('');
                }
            })
            .catch((error) => {
                const notificationObj = {
                    isOpen: true,
                    subtitle: 'Something went wrong. Please try again.',
                    title: '',
                    kind: 'error',
                };
                dispatch(setNotification(notificationObj));
            });
    });
};

export const deleteElements = async (request, dispatch) =>
    new Promise((resolve, reject) => {
        const apiUrl = `${API_URL}deleteElements`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('');
            })
            .then((responseJson) => {
                if (responseJson['status-code'] === 0) {
                    resolve(responseJson);
                } else {
                    reject(responseJson);
                    throw new Error('');
                }
            })
            .catch((error) => {
                const notificationObj = {
                    isOpen: true,
                    subtitle: 'Something went wrong. Please try again.',
                    title: '',
                    kind: 'error',
                };
                dispatch(setNotification(notificationObj));
            });
    });

export const createElement = async (request, dispatch) =>
    new Promise((resolve, reject) => {
        const apiUrl = `${API_URL}createElement`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('');
            })
            .then((responseJson) => {
                if (responseJson['status-code'] === 0) {
                    resolve(responseJson);
                } else {
                    reject(responseJson);
                    throw new Error('');
                }
            })
            .catch((error) => {
                const notificationObj = {
                    isOpen: true,
                    subtitle: 'Something went wrong. Please try again.',
                    title: '',
                    kind: 'error',
                };
                dispatch(setNotification(notificationObj));
            });
    });

export const editElement = async (request, dispatch) =>
    new Promise((resolve, reject) => {
        const apiUrl = `${API_URL}editElement`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('');
            })
            .then((responseJson) => {
                if (responseJson['status-code'] === 0) {
                    resolve(responseJson);
                } else {
                    reject(responseJson);
                    throw new Error('');
                }
            })
            .catch((error) => {
                const notificationObj = {
                    isOpen: true,
                    subtitle: 'Something went wrong. Please try again.',
                    title: '',
                    kind: 'error',
                };
                dispatch(setNotification(notificationObj));
            });
    });

// Keeping this call for future refrence
/* export const searchElements = async (request, dispatch) => {
    new Promise((resolve, reject) => {
        const apiUrl = `${API_URL}getElementSearchResultsV2`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('');
            })
            .then((responseJson) => {
                if (responseJson['status-code'] === 0) {
                    dispatch(fetchAllElements(responseJson));
                    resolve(responseJson);
                } else {
                    dispatch(fetchAllElements(responseJson));
                    reject(responseJson);
                    throw new Error('');
                }
            })
            .catch((error) => {
                const notificationObj = {
                    isOpen: true,
                    subtitle: 'Something went wrong. Please try again.',
                    title: '',
                    kind: 'error',
                };
                dispatch(setNotification(notificationObj));
            });
    });
}; */
