import { SET_NOTIFICATION } from '../actionTypes/actionTypes.js';

const initialState = {
    isOpen: false,
    subtitle: null,
    title: null,
    kind: 'sucess',
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                isOpen: action.payload.isOpen,
                subtitle: action.payload.subtitle,
                title: action.payload.title,
                kind: action.payload.kind,
            };
        default:
            return state;
    }
};

export default notificationReducer;
