// Elements List
export const FETCH_ELEMENTS_LIST = 'FETCH_ELEMENTS_LIST';
export const CLEAR_ELEMENTS_LIST = 'CLEAR_ELEMENTS_LIST';
export const ELEMENTS_LIST_PAGINATION = 'ELEMENTS_LIST_PAGINATION';
export const CLEAR_EDIT_ELEMENT = 'CLEAR_EDIT_ELEMENT';
export const EDIT_ELEMENT_NEW_DATA = 'EDIT_ELEMENT_NEW_DATA';
export const SET_ELEMENT_TO_EDIT = 'SET_ELEMENT_TO_EDIT';
export const FETCH_ELEMENTS_LIST_MASTER_DATA =
    'FETCH_ELEMENTS_LIST_MASTER_DATA';
export const FETCH_KEYWORD_LIST_MASTER_DATA = 'FETCH_KEYWORD_LIST_MASTER_DATA';

// Notification
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

// Element Search
export const SEARCH_BAR_INPUT = 'SEARCH_BAR_INPUT';
