import { configureStore } from '@reduxjs/toolkit';
import elementsListReducer from './reducers/elementsListReducer.js';
import notificationReducer from './reducers/notificationReducer.js';

export const store = configureStore({
    reducer: {
        elements: elementsListReducer,
        notification: notificationReducer,
    },
});
