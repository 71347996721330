import {
    FETCH_ELEMENTS_LIST,
    CLEAR_ELEMENTS_LIST,
    ELEMENTS_LIST_PAGINATION,
    CLEAR_EDIT_ELEMENT,
    EDIT_ELEMENT_NEW_DATA,
    SET_ELEMENT_TO_EDIT,
    SEARCH_BAR_INPUT,
    FETCH_ELEMENTS_LIST_MASTER_DATA,
    FETCH_KEYWORD_LIST_MASTER_DATA,
} from '../actionTypes/actionTypes.js';

const initialState = {
    elements_list: null,
    paginationData: {
        currentPageSize: 10,
        currentPage: 1,
    },
    editElementData: {
        element_id: '',
        element_edit: [
            {
                field_name: 'element_name',
                old_value: '',
                new_value: '',
            },
            {
                field_name: 'category',
                old_value: '',
                new_value: '',
            },
            {
                field_name: 'definition',
                old_value: '',
                new_value: '',
            },
            {
                field_name: 'relevance',
                old_value: '',
                new_value: '',
            },
            {
                field_name: 'risk_factors',
                old_value: '',
                new_value: '',
            },
            {
                field_name: 'doc_section',
                old_value: '',
                new_value: '',
            },
            {
                field_name: 'key_words',
                old_value: '',
                new_value: '',
            },
        ],
    },
    searchBarInput: null,
    elementsList_masterData: null,
    keyword_masterData: null,
};

const elementsListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ELEMENTS_LIST:
            return {
                ...state,
                elements_list: action.payload,
            };
        case CLEAR_ELEMENTS_LIST:
            return {
                ...state,
                elements_list: null,
            };
        case ELEMENTS_LIST_PAGINATION:
            return {
                ...state,
                paginationData: action.payload,
            };
        case CLEAR_EDIT_ELEMENT:
            return {
                ...state,
                editElementData: {
                    element_id: '',
                    element_edit: [
                        {
                            field_name: 'element_name',
                            old_value: '',
                            new_value: '',
                        },
                        {
                            field_name: 'category',
                            old_value: '',
                            new_value: '',
                        },
                        {
                            field_name: 'definition',
                            old_value: '',
                            new_value: '',
                        },
                        {
                            field_name: 'relevance',
                            old_value: '',
                            new_value: '',
                        },
                        {
                            field_name: 'risk_factors',
                            old_value: '',
                            new_value: '',
                        },
                        {
                            field_name: 'doc_section',
                            old_value: '',
                            new_value: '',
                        },
                        {
                            field_name: 'key_words',
                            old_value: '',
                            new_value: '',
                        },
                    ],
                },
            };
        case EDIT_ELEMENT_NEW_DATA:
            return {
                ...state,
                editElementData: {
                    ...state.editElementData,
                    element_edit: [
                        {
                            ...state.editElementData.element_edit[0],
                            new_value: action.payload.element_name,
                        },
                        {
                            ...state.editElementData.element_edit[1],
                            new_value: action.payload.category,
                        },
                        {
                            ...state.editElementData.element_edit[2],
                            new_value: action.payload.definition,
                        },
                        {
                            ...state.editElementData.element_edit[3],
                            new_value: action.payload.relevance,
                        },
                        {
                            ...state.editElementData.element_edit[4],
                            new_value: action.payload.risk_factors,
                        },
                        {
                            ...state.editElementData.element_edit[5],
                            new_value: action.payload.doc_section,
                        },
                        {
                            ...state.editElementData.element_edit[6],
                            new_value: action.payload.key_words,
                        },
                    ],
                },
            };
        case SET_ELEMENT_TO_EDIT:
            return {
                ...state,
                editElementData: {
                    ...state.editElementData,
                    element_id: action.payload.element_id,
                    element_edit: [
                        {
                            ...state.editElementData.element_edit[0],
                            old_value: action.payload.element_name,
                        },
                        {
                            ...state.editElementData.element_edit[1],
                            old_value: action.payload.category,
                        },
                        {
                            ...state.editElementData.element_edit[2],
                            old_value: action.payload.definition,
                        },
                        {
                            ...state.editElementData.element_edit[3],
                            old_value: action.payload.relevance,
                        },
                        {
                            ...state.editElementData.element_edit[4],
                            old_value: action.payload.risk_factors,
                        },
                        {
                            ...state.editElementData.element_edit[5],
                            old_value: action.payload.doc_section,
                        },
                        {
                            ...state.editElementData.element_edit[6],
                            old_value: action.payload.key_words,
                        },
                    ],
                },
            };
        case SEARCH_BAR_INPUT:
            return {
                ...state,
                searchBarInput: action.payload,
            };
        case FETCH_ELEMENTS_LIST_MASTER_DATA:
            return {
                ...state,
                elementsList_masterData: action.payload,
            };
        case FETCH_KEYWORD_LIST_MASTER_DATA:
            return {
                ...state,
                keyword_masterData: action.payload,
            };
        default:
            return state;
    }
};

export default elementsListReducer;
