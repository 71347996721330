export const createSearchRequest = (
    data,
    paginationData,
    sortDirection,
    sortColumn
) => {
    const search = [];
    let request = null;
    data.map((item, index) => {
        const obj = {
            type: item.type,
            data_name: item.data_name,
            data_type: item.data_type,
            data_operator: item.data_operator,
            query: item.query,
            is_exact: item.is_exact,
            connect_operator: index === 0 ? -1 : 1,
        };
        search.push(obj);
    });
    request = {
        search_criteria: search,
        offset:
            paginationData.currentPageSize * (paginationData.currentPage - 1),
        count: paginationData.currentPageSize,
    };
    if (sortDirection !== 'NONE') {
        request.sort_criteria = {
            sort_field: sortColumn,
            sort_type:
                sortDirection === 'ASC'
                    ? 1
                    : sortDirection === 'DESC'
                    ? 0
                    : null,
        };
    }
    return request;
};

export const getDataAttributes = (data) => {
    const newCategoryItems = [];
    const newRelevanceitems = [];
    const newRiskFactorItems = [];
    const newDocSectionsItems = [];

    const mainAttributes = data?.master_data.find(
        (attributes) => attributes.dataset_name === 'main_attributes'
    );
    mainAttributes.dataset.map((attribute) => {
        if (attribute.key === 'category') {
            attribute.valid_values.forEach((value) => {
                newCategoryItems.push({
                    id: value,
                    label: value,
                    type: 'category',
                });
            });
        } else if (attribute.key === 'relevance') {
            attribute.valid_values.forEach((value) => {
                newRelevanceitems.push({
                    id: value,
                    label: value,
                    type: 'relevance',
                });
            });
        } else if (attribute.key === 'risk_factors') {
            attribute.valid_values.forEach((value) => {
                newRiskFactorItems.push({
                    id: value,
                    label: value,
                    type: 'risk_factors',
                });
            });
        } else if (attribute.key === 'doc_section') {
            attribute.valid_values.forEach((value) => {
                newDocSectionsItems.push({
                    id: value,
                    label: value,
                    type: 'doc_section',
                });
            });
        }
    });
    return {
        newCategoryItems,
        newRelevanceitems,
        newRiskFactorItems,
        newDocSectionsItems,
    };
};
