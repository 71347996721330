/**
 * * Copyright Notice: 
Licensed Materials - Property of IBM
IBM Consulting Contract Associate Toolkit
694986P
(C) Copyright IBM Corp. 2021, 2022 All Rights Reserved
US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import React, { useState } from 'react';
import {
    TextArea,
    TextInput,
    FormGroup,
    Stack,
    Row,
    Column,
    Dropdown,
    MultiSelect,
} from '@carbon/react';
import { AddFilled } from '@carbon/react/icons';
import '../SidePanel.scss';

const MAX_LENGTH_TEXT_AREA = 100;
const AddEditElement = (props) => {
    const {
        setElementInfo,
        elementInfo,
        categoryItems,
        relevanceItems,
        riskFactorItems,
        docSectionsItems,
        keywordItems,
        setKeywordItems,
        contentType,
    } = { ...props };
    const [characterLength, setCharacterLength] = useState(
        elementInfo?.definition.length || 0
    );
    const [addKeywordSelected, setAddKeywordSelected] = useState(false);
    const [newKeyword, setNewKeyword] = useState('');
    const [isKeywordValid, setIsKeywordValid] = useState(false);

    const countCharacters = (element) => {
        const lettersLength = element.currentTarget.value.length;
        setCharacterLength(lettersLength);
    };

    const textAreaLabelCount = () => (
        <Row>
            <Column>Element Definition</Column>
            <Column className='textAreaCount'>{characterLength}/100</Column>
        </Row>
    );

    const changeElementValue = (elementId, elementValue) => {
        setElementInfo((prevState) => ({
            ...prevState,
            [elementId]: elementValue,
        }));
    };

    const preselectedItems = (selectedGroup, dropdownGroup) => {
        const result = dropdownGroup.filter((item) =>
            selectedGroup.includes(item.label)
        );
        return result;
    };

    const addNewTempKeyword = (newTempKeyword) => {
        setKeywordItems((prevState) => [
            ...prevState,
            { id: newTempKeyword.replace(/\s+/g, '-'), label: newTempKeyword },
        ]);
        setAddKeywordSelected(false);
        setNewKeyword('');
    };

    const validateNewKeyword = (keyword, keywordList) => {
        const checkIfExist = keywordList.filter(
            (keywordData) =>
                keywordData.label.toLowerCase() === keyword.toLowerCase()
        ).length;
        const keywordCheck = keyword.match(/^ *$/) === null;
        if (checkIfExist === 0 && keywordCheck) {
            setIsKeywordValid(keywordCheck);
        } else {
            setIsKeywordValid(false);
        }
        return keywordCheck;
    };

    return (
        <div className='contentSection'>
            <FormGroup legendText=''>
                <Stack gap={6}>
                    <TextInput
                        id='textInputElementName'
                        labelText='Element Name'
                        name='element_name'
                        onChange={(e) =>
                            changeElementValue(e.target.name, e.target.value)
                        }
                        defaultValue={elementInfo?.element_name || ''}
                    />

                    <Dropdown
                        id='dropdownElementCategory'
                        ariaLabel='Dropdown'
                        items={categoryItems}
                        label=''
                        titleText='Element Category'
                        onChange={(e) =>
                            changeElementValue(
                                e.selectedItem.type,
                                e.selectedItem.label
                            )
                        }
                        selectedItem={elementInfo?.category || ''}
                    />

                    <Dropdown
                        id='dropdownElementRelevance'
                        ariaLabel='Dropdown'
                        items={relevanceItems}
                        label=''
                        titleText='Element Relevance'
                        onChange={(e) =>
                            changeElementValue(
                                e.selectedItem.type,
                                e.selectedItem.label
                            )
                        }
                        selectedItem={elementInfo?.relevance || ''}
                    />

                    <Dropdown
                        id='dropdownRiskFactors'
                        ariaLabel='Dropdown'
                        items={riskFactorItems}
                        label=''
                        titleText='Risk Factors'
                        onChange={(e) =>
                            changeElementValue(
                                e.selectedItem.type,
                                e.selectedItem.label
                            )
                        }
                        selectedItem={elementInfo?.risk_factors || ''}
                    />

                    <TextArea
                        className='sidePanelTextArea'
                        placeholder='Definition'
                        maxLength={MAX_LENGTH_TEXT_AREA}
                        labelText={textAreaLabelCount()}
                        name='definition'
                        onChange={(e) => {
                            changeElementValue(e.target.name, e.target.value);
                            countCharacters(e);
                        }}
                        defaultValue={elementInfo?.definition || ''}
                    />

                    <MultiSelect
                        label='Select Document Section'
                        id='carbon-multiselect-example'
                        titleText='Document Section'
                        helperText=''
                        items={docSectionsItems}
                        itemToString={(item) => (item ? item.label : '')}
                        selectionFeedback='fixed'
                        initialSelectedItems={preselectedItems(
                            elementInfo?.doc_section,
                            docSectionsItems
                        )}
                        onChange={(e) =>
                            changeElementValue('doc_section', e.selectedItems)
                        }
                    />

                    <TextInput
                        className={addKeywordSelected ? null : 'displayNone'}
                        id='textInputKeyword'
                        labelText='Keywords'
                        name='keyword'
                        onChange={(e) => {
                            setNewKeyword(e.target.value);
                            validateNewKeyword(e.target.value, keywordItems);
                        }}
                        value={newKeyword}
                    />

                    <MultiSelect
                        label='Select Keywords'
                        className={addKeywordSelected ? 'displayNone' : null}
                        id='carbon-multiselect-example'
                        titleText='Keywords'
                        helperText=''
                        items={keywordItems}
                        itemToString={(item) => (item ? item.label : '')}
                        selectionFeedback='fixed'
                        initialSelectedItems={preselectedItems(
                            elementInfo?.key_words,
                            keywordItems
                        )}
                        onChange={(e) =>
                            changeElementValue('key_words', e.selectedItems)
                        }
                    />
                </Stack>
                {addKeywordSelected ? (
                    <Row className='mt16'>
                        <Column
                            className='addkeywordSecondaryButton cursor-pointer'
                            onClick={() => {
                                setAddKeywordSelected(false);
                                setNewKeyword('');
                            }}
                        >
                            Cancel
                        </Column>
                        <Column
                            className='cursor-pointer'
                            onClick={() => {
                                if (isKeywordValid) {
                                    addNewTempKeyword(newKeyword);
                                }
                            }}
                        >
                            <span
                                className={
                                    isKeywordValid
                                        ? 'saveKeywordbutton enabled'
                                        : 'saveKeywordbutton disabled'
                                }
                            >
                                Save keyword
                            </span>
                        </Column>
                    </Row>
                ) : (
                    contentType === 'addElement' && (
                        <Row className='mt16'>
                            <Column
                                className='cursor-pointer'
                                onClick={() => {
                                    setAddKeywordSelected(true);
                                    setElementInfo((prevState) => ({
                                        ...prevState,
                                        key_words: '',
                                    }));
                                }}
                            >
                                <span className='addKeywordButton'>
                                    <AddFilled
                                        size={16}
                                        className='addFilledIcon'
                                    />
                                    Add keyword
                                </span>
                            </Column>
                        </Row>
                    )
                )}
            </FormGroup>
        </div>
    );
};

export default AddEditElement;
