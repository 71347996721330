import React from 'react';
import { Modal } from '@carbon/react';

const DeleteElementModal = (props) => {
    const {
        isOpen,
        setDeleteModal,
        selectedRowsState,
        deleteElementsAPI,
        setDeleteModalBtnDisable,
        deleteModalBtnDisable,
    } = {
        ...props,
    };

    const clickConfirm = () => {
        deleteElementsAPI(selectedRowsState);
        setDeleteModalBtnDisable(true);
    };

    return (
        <Modal
            open={isOpen}
            modalHeading='Confirm Element Deletion'
            primaryButtonText='Delete'
            secondaryButtonText='Cancel'
            size='sm'
            preventCloseOnClickOutside
            onSecondarySubmit={() => {
                setDeleteModal(false);
            }}
            onRequestClose={() => {
                setDeleteModal(false);
            }}
            onRequestSubmit={() => clickConfirm()}
            primaryButtonDisabled={deleteModalBtnDisable}
        >
            <p className='marginBottom1rem'>
                This action will delete the element(s) and all the corresponding
                attributes.
            </p>
            <p>Are you sure you want to delete?</p>
        </Modal>
    );
};

export default DeleteElementModal;
